import { Form, Formik } from 'formik';
import { useContext, useState } from 'react';

import styled from 'styled-components';

import { Webhook } from '../../../../../typings/Webhook.interface';
import CopyableField from '../../../dashboard/components/common/CopyableField';
import TextInput from '../../../dashboard/components/common/Form/Fields/TextInput';
import TabsFormSection from '../../../dashboard/components/common/Form/TabsFormSection';
import Button from '../../../dashboard/components/common/base/Button';
import { StyledCard } from '../../../dashboard/components/common/base/Card';
import Divider from '../../../dashboard/components/common/base/Divider';
import Link from '../../../dashboard/components/common/base/Link';
import Text from '../../../dashboard/components/common/base/Text';
import { Div } from '../../../dashboard/components/common/helpers/StyledUtils';
import LINKS from '../../../dashboard/configs/links';
import { isValidUrl } from '../../../dashboard/utils';
import isEmpty from '../../../dashboard/utils/isEmpty';
import { ConsoleGlobalContext } from '../context/ConsoleGlobalContext';
import { ConsoleResourceContext } from '../context/ConsoleResourceContext';

const getOS = (): string => {
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];

  let os = '';

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macos';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows';
  } else if (!os && /Linux/.test(platform)) {
    os = 'linux';
  }

  return os;
};

const StyledStepSectionWrapper = styled(Div)`
  position: relative;
  &::before {
    position: absolute;
    top: 3px;
    bottom: 32px;
    left: 8px;
    border-left: 1px dashed ${({ theme }) => theme.colors.outline.neutral};
    content: '';
  }
`;

const StyledStepSection = styled(Div)`
  &::before {
    position: absolute;
    left: 1px;
    margin-top: 3px;
    content: '';
    height: 14px;
    width: 14px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.outline.neutral};
  }
`;

const getInitialValuesForWebhook = (webhook: Webhook) => ({
  tab: webhook.destination?.url ? 'http' : 'localhost',
  url: webhook.destination?.url,
  cli_path: webhook.destination?.cli_path,
  cli_install_tab: getOS() || 'macos',
});

const DestinationForm: React.FC<{
  onSuccess?: (webhook: Webhook) => void;
  submit_label: string;
}> = ({ onSuccess, submit_label }) => {
  const { setWebhook, source, webhook } = useContext(ConsoleResourceContext);
  const { HookdeckAPI, cli_key } = useContext(ConsoleGlobalContext);
  const has_cli_path = !!webhook?.destination?.cli_path;
  const [show_cli_instructons, setShowCliInstructions] = useState(!has_cli_path);

  if (!webhook || (!isEmpty(webhook) && webhook.source?.id !== source.id)) {
    return null;
  }

  return (
    <Formik
      key={webhook.id}
      validateOnChange={true}
      validateOnMount={true}
      initialValues={
        !webhook
          ? {
              tab: 'http',
              url: '',
              cli_install_tab: getOS() || 'macos',
              cli_path: '/',
            }
          : getInitialValuesForWebhook(webhook)
      }
      validate={(values) => {
        const errors: { url?: string } = {};
        if (values.tab === 'http') {
          if (values.url) {
            if (!isValidUrl(values.url)) {
              errors.url = 'Must be a valid HTTP url';
            }
          }
        }
        return errors;
      }}
      onSubmit={(values, { resetForm }) => {
        const method = HookdeckAPI.webhooks.upsert({
          name: 'default',
          source_id: source.id,
          destination: {
            name: `${source.name}-default`,
            url: values.tab === 'http' ? values.url : null,
            cli_path: values.tab === 'localhost' ? values.cli_path : null,
          },
        });
        return method.then((webhook) => {
          setWebhook(webhook);
          onSuccess && onSuccess(webhook);
          resetForm({ values: getInitialValuesForWebhook(webhook) });
          return;
        });
      }}>
      {({ values: { url: url_value }, isValid, isSubmitting, touched }) => (
        <Form>
          <TabsFormSection
            name="tab"
            default_tab="http"
            tabs={[
              {
                icon: 'http',
                label: 'HTTP',
                key: 'http',
                fields: (
                  <>
                    <Div p={{ x: 4 }}>
                      <Text heading as="p" m={{ b: 1 }}>
                        Destination URL
                      </Text>
                      <Text muted as="p" m={{ b: 3 }}>
                        The HTTP URL that will receive the requests
                      </Text>
                      <TextInput name="url" placeholder={`https://example.com`} required />
                      {url_value && url_value.includes('ngrok.io') && (
                        <Text muted m={{ b: 4 }}>
                          Using Ngrok?{' '}
                          <Link
                            primary
                            href={LINKS.product_docs.cli}
                            target="_blank"
                            rel="noreferrer">
                            Try our CLI{' '}
                          </Link>
                          with free permanent URLs
                        </Text>
                      )}
                    </Div>
                    <Div p={{ x: 4 }} m={{ b: 5 }}>
                      <Button submit disabled={!isValid || isSubmitting}>
                        {submit_label}
                      </Button>
                    </Div>
                  </>
                ),
              },
              {
                icon: 'terminal',
                label: 'Localhost',
                key: 'localhost',
                fields: (
                  <>
                    {has_cli_path && (
                      <Div p={{ x: 4 }} m={{ b: 4 }}>
                        <Text heading as="p" m={{ b: 1 }}>
                          Localhost Path
                        </Text>
                        <Text muted as="p" m={{ b: 3 }}>
                          The URL path that will receive the requests.
                        </Text>
                        <TextInput name="cli_path" placeholder={`/webhooks`} required />
                        <Div m={{ b: 5 }} flex={{ align: 'center' }}>
                          <Button
                            submit
                            disabled={
                              !Object.values(touched).some((v) => v) || !isValid || isSubmitting
                            }>
                            {submit_label}
                          </Button>
                          <Link
                            m={{ l: 4 }}
                            icon={show_cli_instructons ? 'arrow_drop_up' : 'arrow_drop_down'}
                            onClick={() => setShowCliInstructions(!show_cli_instructons)}>
                            CLI Instuctions
                          </Link>
                        </Div>
                      </Div>
                    )}
                    {show_cli_instructons && (
                      <Div p={{ x: 4, b: 4 }}>
                        {!has_cli_path && (
                          <Text as="p" m={{ b: 7 }}>
                            Run events locally by installing the Hookdeck CLI.
                          </Text>
                        )}
                        <StyledStepSectionWrapper>
                          <StyledStepSection p={{ l: 7 }}>
                            <Text as="p" heading m={{ b: 1 }}>
                              Step 1
                            </Text>
                            <Text as="p" m={{ b: 3 }}>
                              Install the Hookdeck CLI on your device
                            </Text>
                            <StyledCard>
                              <TabsFormSection
                                name="cli_install_tab"
                                default_tab="macos"
                                tabs={[
                                  {
                                    label: 'macOS',
                                    key: 'macos',
                                    fields: (
                                      <Div p={{ x: 4, b: 4 }}>
                                        <Text m={{ b: 3 }}>Install with Brew</Text>
                                        <CopyableField
                                          monospace
                                          value="brew install hookdeck/hookdeck/hookdeck"
                                        />
                                      </Div>
                                    ),
                                  },
                                  {
                                    label: 'Windows',
                                    key: 'windows',
                                    fields: (
                                      <Div p={{ x: 4, b: 4 }}>
                                        <Text m={{ b: 3 }}>Install with Scoop</Text>
                                        <CopyableField
                                          m={{ b: 4 }}
                                          monospace
                                          value="scoop bucket add hookdeck https://github.com/hookdeck/scoop-hookdeck-cli.git"
                                        />
                                        <CopyableField monospace value="scoop install hookdeck" />
                                      </Div>
                                    ),
                                  },
                                  {
                                    label: 'Linux',
                                    key: 'linux',
                                    fields: (
                                      <Div p={{ x: 4, b: 4 }}>
                                        <Text m={{ b: 3 }}>
                                          Download the{' '}
                                          <Link
                                            href="https://github.com/hookdeck/hookdeck-cli/releases/latest"
                                            target="_blank">
                                            latest release's tar.gz file
                                          </Link>{' '}
                                          then unzip the file.
                                        </Text>
                                        <CopyableField
                                          monospace
                                          value="tar -xvf hookdeck_X.X.X_linux_x86_64.tar.gz"
                                        />
                                      </Div>
                                    ),
                                  },
                                  {
                                    label: 'Docker',
                                    key: 'docker',
                                    fields: (
                                      <Div p={{ x: 4, b: 4 }}>
                                        <Text m={{ b: 3 }}>
                                          Pull the <code>hookdeck/hookdeck-cli</code> image
                                        </Text>
                                        <CopyableField
                                          m={{ b: 4 }}
                                          monospace
                                          value="docker pull hookdeck/hookdeck-cli"
                                        />
                                        <CopyableField
                                          monospace
                                          value="docker run --rm -it hookdeck/hookdeck-cli version"
                                        />
                                      </Div>
                                    ),
                                  },
                                  {
                                    label: 'NPM',
                                    key: 'npm',
                                    fields: (
                                      <Div p={{ x: 4, b: 4 }}>
                                        <CopyableField
                                          monospace
                                          value="npm install @hookdeck/cli -g"
                                        />
                                      </Div>
                                    ),
                                  },
                                ]}
                              />
                            </StyledCard>
                          </StyledStepSection>
                          <StyledStepSection p={{ l: 7, t: 7 }}>
                            <Text as="p" heading m={{ b: 1 }}>
                              Step 2
                            </Text>
                            <Text as="p" m={{ b: 3 }}>
                              Login and start the CLI with those commands
                            </Text>
                            <CopyableField
                              m={{ b: 4 }}
                              monospace
                              value={`hookdeck login --cli-key ${cli_key.key}`}
                            />
                            <CopyableField
                              monospace
                              value={`hookdeck listen [port] ${source.name}`}
                              valueElement={
                                <Text ellipsis monospace>
                                  hookdeck listen <code>PORT</code> {source.name}
                                </Text>
                              }
                            />
                          </StyledStepSection>
                          <StyledStepSection p={{ l: 7, t: 7 }}>
                            <Text as="p" heading m={{ b: 1 }}>
                              Step 3
                            </Text>
                            <Text as="p" m={{ b: 3 }}>
                              Keep an eye on your Events list to view your newest events
                            </Text>
                          </StyledStepSection>
                        </StyledStepSectionWrapper>
                      </Div>
                    )}
                  </>
                ),
              },
            ]}
          />
          <Divider />
        </Form>
      )}
    </Formik>
  );
};

export default DestinationForm;
