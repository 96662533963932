import { createContext, PropsWithChildren, useCallback, useContext } from 'react';
import { Event } from '../../../../../typings/Event.interface';
import { Request } from '../../../../../typings/Request.interface';

import { Webhook } from '../../../../../typings/Webhook.interface';
import { useToasts } from '../../../dashboard/components/common/Toast';
import { ConsoleGlobalContext } from './ConsoleGlobalContext';
import { ConsoleResourceContext } from './ConsoleResourceContext';
import { ConsoleSidebarContext } from './ConsoleSidebarContext';
import useConsoleEntries from './useConsoleEntries';

interface ConsoleEntriesContextProps {
  entries?: (Event | Request)[];
  sendRequest: (id: string, webhook: Webhook) => Promise<void>;
  selectEventOrRequest: (id: string, entry: Event | Request) => void;
  retryEvent: (id: string) => Promise<void>;
}

export const ConsoleEntriesContext = createContext<ConsoleEntriesContextProps>({
  entries: undefined,
  sendRequest: () => Promise.resolve(),
  selectEventOrRequest: () => null,
  retryEvent: () => Promise.resolve(),
});

export const ConsoleEntriesContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { HookdeckAPI } = useContext(ConsoleGlobalContext);
  const { source, webhook } = useContext(ConsoleResourceContext);
  const { selectEventOrRequest } = useContext(ConsoleSidebarContext);
  const { addToast } = useToasts();

  const { entries, addEvent, mutateEvent } = useConsoleEntries({ source, webhook });

  const sendRequest = useCallback(
    (id: string, webhook: Webhook) => {
      return HookdeckAPI?.requests.retry(id, [webhook.id]).then(({ events }) => {
        const event = events?.find((event) => event.webhook_id === webhook.id);
        if (event) {
          addEvent(event);
          selectEventOrRequest(event.id, event);
        }
      });
    },
    [selectEventOrRequest, HookdeckAPI, addEvent],
  );

  const retryEvent = useCallback(
    (id: string) => {
      return HookdeckAPI?.events
        .retry(id)
        .then(({ event }) => {
          addToast('success', 'Retrying event');
          mutateEvent(event);
        })
        .catch((e) => {
          if (e.response?.code === 'WEBHOOK_INVALID') {
            addToast('error', 'Failed to retry, connection disabled or paused');
          } else if (e.response?.code === 'CLI_NOT_CONNECTED') {
            addToast('error', 'Failed to retry, your CLI is not connected');
          } else {
            addToast('error', 'Failed to retry');
          }
        });
    },
    [selectEventOrRequest, HookdeckAPI, addEvent, mutateEvent],
  );

  const context: ConsoleEntriesContextProps = {
    entries,
    selectEventOrRequest: selectEventOrRequest,
    sendRequest,
    retryEvent,
  };

  return (
    <ConsoleEntriesContext.Provider value={context}>{children}</ConsoleEntriesContext.Provider>
  );
};
