import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Button from '../../dashboard/components/common/base/Button';
import { StyledCardSection } from '../../dashboard/components/common/base/Card';
import Link from '../../dashboard/components/common/base/Link';
import Text from '../../dashboard/components/common/base/Text';
import Tooltip from '../../dashboard/components/common/base/Tooltip';
import Dropdown from '../../dashboard/components/common/Dropdown';
import { Div } from '../../dashboard/components/common/helpers/StyledUtils';
import useLocalStorage from '../../dashboard/components/hooks/useLocalStorage';
import LINKS from '../../dashboard/configs/links';

const cards: { title: string; description: string; asset: string; link: string }[] = [
  {
    title: 'Observability for peace of mind',
    description: 'From high-level trends to event tracing and inspecting, Hookdeck has you covered',
    asset: '/img/discover-observability.svg',
    link: LINKS.landing_pages.monitor,
  },
  {
    title: 'Fix problems before impact',
    description:
      'Resolve problems in record time with Issue Triggers, Issues, manual & bulk retries',
    asset: '/img/discover-problems.svg',
    link: LINKS.landing_pages.recover,
  },
  {
    title: 'Receive every request safely',
    description:
      'Control your event stream with rate limits, customizable retries, and event pausing',
    asset: '/img/discover-safely.svg',
    link: LINKS.landing_pages.production,
  },
  {
    title: 'Centralize events in one platform',
    description:
      'Determine where your events go and tweak their content with Fan Out, Filters, and Transformation',
    asset: '/img/discover-manage.svg',
    link: LINKS.landing_pages.manage,
  },
];

const StyledImg = styled.img(
  ({ theme }) => css`
    width: 100%;
    box-shadow: ${theme.elevation[1]};
    border-radius: ${theme.radius.normal};
    overflow: hidden;
    background-color: transparent;
  `,
);

const StyledProgressDot = styled.span<{ active: boolean }>(
  ({ theme, active }) => css`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin: ${theme.spacing(1)};
    background-color: ${active
      ? theme.colors.surface.base.active.primary
      : theme.colors.surface.base.active.neutral_variant};
  `,
);

const DiscoverCards: React.FC = () => {
  const [card_index, setCardIndex] = useLocalStorage('pref:discover-card:index', 0);
  const card = cards[card_index];
  const theme = useTheme();
  return (
    <Dropdown
      placement="bottom-end"
      renderToggle={(open, toggle) => (
        <Button
          outline
          badge={
            card_index <= cards.length - 2
              ? { label: String(cards.length - card_index) }
              : undefined
          }
          onClick={() => toggle(!open)}>
          Discover{' '}
        </Button>
      )}>
      <StyledCardSection p={4} w={{ px: 416 }}>
        <Div m={{ b: 4 }}>
          <Text size="s" m={{ b: 1 }}>
            Discover Hookdeck
          </Text>
          <Text heading size="l" m={{ b: 2 }}>
            {card.title}
          </Text>
          <Text muted as="p" m={{ b: 3 }}>
            {card.description}
          </Text>
          <Link href={card.link} target="_blank" icon="arrow_forward">
            Learn more
          </Link>
        </Div>
        <StyledImg
          src={`${VITE_CONSOLE_URL}${card.asset}`}
          style={{ filter: theme.mode === 'dark' ? 'invert(0.9) grayscale(1)' : undefined }}
        />
        <Div flex={{ justify: 'center' }} m={{ t: 4 }}>
          {cards.map((_, index) => (
            <StyledProgressDot key={index} active={index === card_index} />
          ))}
        </Div>
      </StyledCardSection>
      <StyledCardSection p={4} flex={{ justify: 'space-between' }}>
        <Div flex={{ align: 'center' }}>
          <Tooltip tooltip="100k free requests per month" placement="bottom-start">
            <Button
              small
              m={{ r: 3 }}
              primary
              as="a"
              href={`${VITE_APP_URL}/onboarding`}
              icon="upgrade">
              Upgrade for Free
            </Button>
          </Tooltip>
        </Div>
        <Div flex={{ align: 'center' }}>
          <Button
            small
            minimal
            icon="chevron_left"
            disabled={card_index === 0}
            onClick={() => setCardIndex(card_index - 1)}
          />
          <Button
            small
            margin={{ l: 2 }}
            minimal
            icon="chevron_right"
            disabled={card_index === cards.length - 1}
            onClick={() => setCardIndex(card_index + 1)}
          />
        </Div>
      </StyledCardSection>
    </Dropdown>
  );
};

export default DiscoverCards;
